<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between mx-10">
<!--      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"-->
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted font-weight-bold">
          Deskki {{ $t("FOOTER.SYSTEM_PATH_1") }} © {{ $moment().year() }}{{ $t("FOOTER.SYSTEM_PATH_2") }}
        </span>
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
<!--        <a-->
<!--          href=""-->
<!--          target="_blank"-->
<!--          class="nav-link pr-0"-->
<!--          >{{ $t("FOOTER.PRIVACY_POLITICS") }}</a-->
<!--        >-->
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>

<style lang="scss" scoped>
  .footer {
    font-size: 12px;
    border-top: 1px solid #E0E0E2;
  }
</style>
