<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: Languages -->
    <b-dropdown
        size="sm"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
        v-b-tooltip.hover.v-dark="$t('TRANSLATOR.SELECT')"
    >
      <template v-slot:button-content>
        <div
            class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
        >
          <span class="svg-icon svg-icon-xl">
            <i class="fas fa-globe"></i>
<!--            <i class="fas fa-language"></i>-->
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-150px">
        <KTDropdownLanguage></KTDropdownLanguage>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Languages -->
    <div class="d-flex align-items-center btn-lg px-md-2 w-md-auto">
      <span class="text-white font-weight-bolder font-size-md d-inline">{{ currentUserAccountInfo.name.display }}</span>
    </div>
    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import i18nService from "@/core/services/i18n.service.js";
import { GET_PROFILE } from "@/core/services/store/profile.module";
import {mapGetters} from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  mounted() {
    this.$store.dispatch(GET_PROFILE);
  },
  components: {
    KTDropdownLanguage,
    KTQuickUser
  },
  methods: {
    // onLanguageChanged() {
    //   this.languageFlag = this.languages.find(val => {
    //     return val.lang === i18nService.getActiveLanguage();
    //   }).flag;
    // }
  },
  computed: {
    ...mapGetters([
      "currentUserAccountInfo"
    ]),
    // getLanguageFlag() {
    //   return this.onLanguageChanged();
    // }
  }
};
</script>

<style lang="scss" scoped>
  @media (max-width: 991.98px) {
    .topbar-mobile-on {

      .topbar {
        margin-top: 52px;
      }
    }
  }
</style>
