<template>
  <div id="kt_header" ref="kt_header" class="header flex-column">
    <div class="header-top">
      <div class="container-fluid">
        <div class="d-none d-lg-flex align-items-center mr-3">
          <!--begin::Logo-->
          <div class="mr-20">
            <router-link to="/">
              <svg height="15" viewBox="0 0 99 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.119 0C18.9502 0 22.8874 6.10714 22.9978 12.2857C23.1082 18.6071 19.1341 25 10.119 25H0V0H10.119ZM5 5V21H10.1556C15.8482 21 18.1038 16.8571 17.9964 12.7857C17.8889 8.89286 15.5976 5 10.1556 5H5ZM44.9168 18.1228H30.6644C30.9606 20.3474 32.9226 21.952 36.1062 21.952C37.7721 21.952 39.9562 21.3321 40.9927 20.2745L43.8802 23.0825C41.9552 25.0518 38.8086 26 36.0322 26C29.7389 26 26 22.1708 26 16.4088C26 10.9386 29.7759 7 35.699 7C41.8072 7 45.6201 10.7198 44.9168 18.1228ZM30 14H40C39.6992 12 37.8195 11 35.188 11C32.7068 11 30.6767 12 30 14ZM62.1636 9.35687L59.6909 12.2576C58.4182 11.0611 56.9636 10.6622 55.2545 10.6622C53.1455 10.6622 51.9818 11.3149 51.9818 12.4389C51.9818 13.5992 53.0364 14.2519 55.3273 14.3969C58.7091 14.6145 63 15.376 63 20.126C63 23.2805 60.4182 26 55.2909 26C52.4545 26 49.6182 25.5286 47 22.8092L49.1818 19.6546C50.4545 21.0687 53.3636 22.1202 55.3636 22.1565C57.0364 22.1927 58.6 21.3225 58.6 20.0172C58.6 18.7844 57.5818 18.2767 55.0364 18.1317C51.6545 17.8779 47.6182 16.645 47.6182 12.584C47.6182 8.45038 51.9091 7 55.1818 7C57.9818 7 60.0909 7.54389 62.1636 9.35687ZM66 0H70.4892V14.4492L76.5974 7.36767H81.9697V7.61803L74.5736 15.6652L83 24.6781V25H77.5909L70.4892 17.0243V25H66V0ZM80 14.2292L85.8588 7H91.0118V7.25558L83.9176 15.4706L92 24.6714V25H86.8118L80 16.858V14.2292ZM98 7V25H94V7H98ZM94 2.5C94 -0.833333 99 -0.833333 99 2.5C99 5.83333 94 5.83333 94 2.5Z" fill="#FFFFFF"/>
              </svg>
<!--              <img-->
<!--                alt="Logo"-->
<!--                :src="layoutConfig('self.logo.default')"-->
<!--                class="max-h-35px"-->
<!--              />-->
            </router-link>
          </div>
          <!--end::Logo-->
          <ul
            class="nav nav-bold"
            role="tablist"
          >
            <li class="nav-item">
              <router-link
                  class="nav-link py-4 px-6 font-weight-bolder"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="true"
                  to="/my-office"
                  active-class="active"
              >{{ $t("MENU.MY_OFFICE") }}</router-link>
            </li>
            <li v-if="isBookingCalendar" class="nav-item">
              <router-link
                  class="nav-link py-4 px-6 font-weight-bolder"
                  data-tab="0"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="true"
                  to="/booking-calendar"
                  active-class="active"
              >{{ $t("MENU.BOOKING_CALENDAR") }}</router-link>
            </li>
<!--            <li class="nav-item">-->
<!--              <router-link-->
<!--                  class="nav-link py-4 px-6 font-weight-bolder"-->
<!--                  data-tab="1"-->
<!--                  data-toggle="tab"-->
<!--                  role="tab"-->
<!--                  aria-selected="true"-->
<!--                  to="/colleagues"-->
<!--                  active-class="active"-->
<!--              >{{ $t("MENU.COLLEAGUES") }}</router-link>-->
<!--            </li>-->
            <li v-if="isManagementRole" class="nav-item">
              <router-link
                  class="nav-link py-4 px-6 font-weight-bolder"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="true"
                  to="/management"
                  active-class="active"
              >{{ $t("MENU.MANAGEMENT") }}</router-link>
            </li>
            <li v-if="isAnalyticsRole" class="nav-item">
              <router-link
                  class="nav-link py-4 px-6 font-weight-bolder"
                  data-tab="2"
                  data-toggle="tab"
                  role="tab"
                  aria-selected="true"
                  to="/analytics"
                  active-class="active"
              >{{ $t("MENU.ANALYTICS") }}</router-link>
            </li>
          </ul>
        </div>
        <KTTopbar></KTTopbar>
      </div>
    </div>
    <div class="header-bottom">
      <div class="container">
        <div
          class="header-navs header-navs-left"
          id="kt_header_navs"
          ref="kt_header_navs"
        >
          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded d-block d-lg-none p-5"
               role="tablist"
          >
            <p class="text-muted pt-5 pb-2">{{ $t("MENU.MAIN_MENU") }}</p>
            <div class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  to="/my-office"
                  active-class="active"
              >
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MENU.MY_OFFICE") }}</span>
              </router-link>
            </div>
            <div v-if="isBookingCalendar" class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  to="/booking-calendar"
                  active-class="active"
              >
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MENU.BOOKING_CALENDAR") }}</span>
              </router-link>
            </div>
<!--            <div class="navi-item mb-2">-->
<!--              <router-link-->
<!--                  class="navi-link py-4"-->
<!--                  to="/colleagues"-->
<!--                  active-class="active"-->
<!--              >-->
<!--                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MENU.COLLEAGUES") }}</span>-->
<!--              </router-link>-->
<!--            </div>-->
            <div v-if="isManagementRole" class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  to="/management"
                  active-class="active"
              >
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MENU.MANAGEMENT") }}</span>
              </router-link>
            </div>
            <div v-if="isAnalyticsRole" class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  to="/analytics"
                  active-class="active"
              >
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("MENU.ANALYTICS") }}</span>
              </router-link>
            </div>
          </div>
          <!--end::Nav-->
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab>
              <div class="tab-pane py-5 show active">
                <!--begin::Menu-->
                <div
                  id="kt_header_menu"
                  ref="kt_header_menu"
                  class="header-menu header-menu-mobile header-menu-layout-default"
                >
                </div>
                <!--end::Menu-->
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  data() {
    return {
      tabIndex: 0
    };
  },
  components: {
    KTTopbar
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    isBookingCalendar() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes('calendar-booking'))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },

    isManagementRole() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes('management'))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },

    isAnalyticsRole() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes('analytics'))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    // headerMenuClasses() {
    //   const classes = this.getClasses("header_menu");
    //   if (typeof classes !== "undefined") {
    //     return classes.join(" ");
    //   }
    //   return null;
    // },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
  ul {
    li {
      .nav-link {
        color: #AAB1CA!important;
        &.active {
          color: #FFFFFF!important;
        }
      }
    }
  }
  .header {
    height: 52px;
    width: 100%;

    .header-top {
      height: 100%;
    }
  }
  .header-bottom {
    height: 0!important;
    display: none;
  }

  @media (max-width: 991.98px) {
    .header {
      height: auto;
    }
    .header-bottom {
      display: flex;
    }
  }
</style>
